import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faChain, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { map, Subject, takeUntil } from 'rxjs';
import { Banner, BannerType } from 'src/app/core/models/Banner';
import { TipoTermo } from 'src/app/core/models/ConsentForm';
import { User } from 'src/app/core/models/User';
import { BannerService } from 'src/app/core/services/banner';
import { ConfigService } from 'src/app/core/services/config';
import { LoaderService } from 'src/app/core/services/loader';
import { isPolicysAccepted, loadConsentForms, showPolicysAcceptance } from 'src/app/core/store/actions';
import { selectConsentForms, selectUser, selectUserType } from 'src/app/core/store/selectors';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('next') nextButton!: ElementRef;
  public config = inject(ConfigService);
  bannerService = inject(BannerService);
  loader = inject(LoaderService);
  store = inject(Store);
  idClient!: string;
  banners!: Banner[];
  cards!: Banner[];
  quickLinks!: Banner[];
  isDynamicCarouselRotate: boolean = false;
  isBannersTypes: boolean = false;
  destroyed$ = new Subject<void>();
  carouselRideMode = 'false';
  userType$ = this.store.select(selectUserType);
  loggedUser$ = this.store.select(selectUser);
  consentForm$ = this.store.select(selectConsentForms);
  portalTitle$ = this.userType$.pipe(
    map((userType) => (userType === 'PJ' ? 'PORTAL_PJ_TITLE' : 'PORTAL_PF_TITLE')),
    map((config) => this.config.getConfiguration(config))
  );

  faChevronRight = faChevronRight;
  faChain = faChain;

  ngOnInit(): void {
    this.isDynamicCarouselRotate = this.config.getConfiguration('SHOUD_BANNER_CAROUSEL_DYNAMIC_ROTATE');
    this.isBannersTypes = this.config.getConfiguration('SHOUD_ADD_BANNERS_TYPES');
    this.carouselRideMode = this.isDynamicCarouselRotate ? 'carousel' : 'false';
    this.loggedUser$.subscribe((res: User | undefined) => {
      if (res) {
        this.idClient = res?.idClient;
        this.loadBanners();
        this.store.dispatch(loadConsentForms());
        this.consentForm$.subscribe((response) => {
          if (
            response.length > 0 &&
            response.filter((c) => c.termo == TipoTermo['PoliticaPrivacidade']).length > 0 &&
            response.filter((c) => c.termo == TipoTermo['PoliticaSeguranca']).length > 0
          ) {
            this.store.dispatch(isPolicysAccepted());
          } else {
            this.store.dispatch(showPolicysAcceptance());
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    setTimeout(() => {
      this.store.dispatch(isPolicysAccepted());
    }, 100);
  }

  //carrega banner
  private loadBanners(): void {
    this.loader.show('Carregando informações...');

    this.bannerService
      .listBanners(this.idClient)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((banners: Banner[]) => {
        this.filterBannersCarousel(banners);
        this.initAutoPlayCarousel();

        if (this.isBannersTypes) {
          this.filterBannersCard(banners);
          this.filterBannersQuickLinks(banners);
        }
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private filterBannersCard(banners: Banner[]) {
    let cards = banners.filter((b) => b.tipo == BannerType.Card);
    cards.sort((a, b) => new Date(b.dataCriacao).getTime() - new Date(a.dataCriacao).getTime());
    this.cards = cards.slice(0, 3);
    this.cards.forEach((card) => {
      var hasHttpProtocol = card.linkTo.startsWith('http');
      if (!hasHttpProtocol) {
        card.linkTo = `https://${card.linkTo}`;
      }
    });
  }

  private filterBannersQuickLinks(banners: Banner[]) {
    let quickLinks = banners.filter((b) => b.tipo == BannerType.QuickLink);

    quickLinks.sort((a, b) => new Date(b.dataCriacao).getTime() - new Date(a.dataCriacao).getTime());
    this.quickLinks = quickLinks.slice(0, 4);
    this.quickLinks.forEach((quickLink) => {
      var hasHttpProtocol = quickLink.linkTo.startsWith('http');
      if (!hasHttpProtocol) {
        quickLink.linkTo = `https://${quickLink.linkTo}`;
      }
    });
  }

  private filterBannersCarousel(banners: Banner[]) {
    this.banners = banners.filter((b) => b.tipo == BannerType.Carousel);
  }

  private initAutoPlayCarousel() {
    if (this.banners && this.isDynamicCarouselRotate) {
      setTimeout(() => {
        this.onNextBannerClick();
      }, 5000);
    }
  }

  onNextBannerClick() {
    this.nextButton.nativeElement.click();
  }
}
